// Generated by Framer (6774e0b)

import { addFonts, cx, CycleVariantState, getFonts, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import CalendarImage from "./ua42PrHe6";
const CalendarImageFonts = getFonts(CalendarImage);

const cycleOrder = ["XdQ7xuTWl"];

const variantClassNames = {XdQ7xuTWl: "framer-v-p0fq30"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "XdQ7xuTWl", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "XdQ7xuTWl", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-G7dky", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-p0fq30", className)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"XdQ7xuTWl"} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} transition={transition}><motion.div className={"framer-16mo68o-container"} layoutDependency={layoutDependency} layoutId={"v0tBAEyQk-container"} transition={transition}><CalendarImage height={"100%"} id={"v0tBAEyQk"} layoutId={"v0tBAEyQk"} style={{height: "100%", width: "100%"}} variant={"nq63qERtc"} width={"100%"}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-G7dky [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-G7dky * { box-sizing: border-box; }", ".framer-G7dky .framer-56nn5l { display: block; }", ".framer-G7dky .framer-p0fq30 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; padding: 0px 0px 0px 0px; position: relative; width: 810px; }", ".framer-G7dky .framer-16mo68o-container { flex: none; height: 524px; position: relative; width: 720px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-G7dky .framer-p0fq30 { gap: 0px; } .framer-G7dky .framer-p0fq30 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-G7dky .framer-p0fq30 > :first-child { margin-left: 0px; } .framer-G7dky .framer-p0fq30 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 524
 * @framerIntrinsicWidth 810
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 */
const FramerY5Ufd353x: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerY5Ufd353x;

FramerY5Ufd353x.displayName = "DesktopMobileDevice";

FramerY5Ufd353x.defaultProps = {height: 524, width: 810};

addFonts(FramerY5Ufd353x, [...CalendarImageFonts])